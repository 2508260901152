import { DateTime } from 'luxon';
import { getTagsForCSVExport } from './csvHelpers';

// FIXME:
// for each track, decide which publishers apply
//  - If no ASCAP composer => only library publisher
//  - If only ASCAP composers => only ASCAP
//  - If mixed => both library & ASCAP
// then always produce exactly 6 publishers (fill the rest with blanks)
export function calculatePublishers(rowObject: any) {
  // 2) decide which "raw" publishers to include

  // a helper function for building a publisher entry
  const createPublisherValues = (
    i: number,
    publisherNameKey: string | undefined,
    publisherProKey: string | undefined,
    publisherProValue: string | undefined,
    publisherIpiKey: string | undefined
  ) => {
    return [
      {
        name: `PUBLISHER:${i}: Name`,
        ...(publisherNameKey ? { key: publisherNameKey } : { value: '' }),
      },
      {
        name: `PUBLISHER:${i}: Capacity`,
        value: 'Original Publisher',
      },
      {
        name: `PUBLISHER:${i}: Society`,
        ...(publisherProKey
          ? { key: publisherProKey }
          : { value: publisherProValue }),
      },
      {
        name: `PUBLISHER:${i}: IPI`,
        ...(publisherIpiKey ? { key: publisherIpiKey } : { value: '' }),
      },
      {
        name: `PUBLISHER:${i}: Territory`,
        value: 'WORLD',
      },
      {
        name: `PUBLISHER:${i}: Owner Performance Share %`,
        value: '50', // TODO: how this?
      },
      {
        name: `PUBLISHER:${i}: Owner Mechanical Share %`,
        value: '100', // TODO: how this?
      },
      {
        name: `PUBLISHER:${i}: Collection Performance Share %`,
        value: '50', // TODO: how this?
      },
      {
        name: `PUBLISHER:${i}: Collection Mechanical Share %`,
        value: '100', // TODO: how this?
      },
    ];
  };

  const publisherValues = [];
  // 1) detect composers
  const maxComposers = 6; // or however many you allow
  let hasAscapComposer = false;
  let hasNonAscapComposer = false;

  for (let i = 0; i < maxComposers; i++) {
    const pro = rowObject[`composers.${i}.pro`];
    if (!pro) continue;
    const proTrim = pro.trim().toUpperCase();
    if (proTrim === 'ASCAP') {
      if (!hasAscapComposer) {
        hasAscapComposer = true;
        publisherValues.push(
          ...createPublisherValues(
            i + 1,
            'library.ascapPublisherName',
            undefined,
            'ASCAP',
            'library.ascap_ipi'
          )
        );
      }
    } else {
      if (!hasNonAscapComposer) {
        hasNonAscapComposer = true;
        publisherValues.push(
          ...createPublisherValues(
            i + 1,
            'library.publisherName',
            'library.pro',
            undefined,
            'library.ipi'
          )
        );
      }
    }
  }
  while (publisherValues.length * 9 < 6 * 9) {
    const length: number =
      publisherValues.length === 0 ? 0 : publisherValues.length / 9;
    publisherValues.push(
      ...createPublisherValues(
        length + 1,
        undefined,
        undefined,
        undefined,
        undefined
      )
    );
  }

  return publisherValues;
}

// const publishers = calculatePublishers({});

// Define the column names, values and order in the traceability report
export const exportMapping_Cadenzabox = [
  {
    name: 'LIBRARY: Groups',
    value: '',
  },
  {
    name: 'LIBRARY: Code',
    key: 'library.libraryCode',
  },
  {
    name: 'LIBRARY: Name',
    key: 'library.name',
  },
  {
    name: 'ALBUM: Code',
    key: 'album.metadata.albumCode',
  },
  {
    name: 'ALBUM: Title',
    key: 'album.name',
  },
  {
    name: 'ALBUM: Groups',
    value: '',
  },
  {
    name: 'ALBUM: Description',
    key: 'album.description',
  },
  {
    name: 'ALBUM: Release Date',
    calculate: (rowObject: any) => {
      const iso = rowObject['album.metadata.releaseDate'];
      if (!iso) return '';
      return DateTime.fromISO(iso).toFormat('dd/MM/yyyy');
    },
  },
  {
    name: 'ALBUM: Artwork Filename',
    value: '',
  },
  {
    name: 'ALBUM:CATEGORY: Keywords',
    calculate: getTagsForCSVExport('albumTags', 'KEYWORD'),
  },
  {
    name: 'ALBUM:CATEGORY: Tags',
    value: '',
  },
  {
    name: 'ALBUM:CATEGORY: Styles',
    calculate: getTagsForCSVExport('albumTags', 'STYLE'),
  },
  {
    name: 'TRACK: Is Published',
    calculate: (rowObject: any) =>
      rowObject['album.status'] === 'DONE' ? 'Y' : 'N',
  },
  {
    name: 'TRACK: Album Chapter',
    value: '',
  },
  {
    name: 'TRACK: CODE',
    calculate: (rowObject: any) =>
      `${rowObject['album.metadata.albumCode']}-${rowObject['track.metadata.trackNumber']}`,
  },
  {
    name: 'TRACK: Title',
    key: 'track.name',
  },
  {
    name: 'TRACK: Description',
    calculate: function (rowObject: any) {
      const catalogue = rowObject['track.metadata.catalogue'];

      const tags = Object.entries(rowObject).filter(
        ([k, _]) => k.startsWith('tags') && k.includes('.name')
      );

      console.log('TRACK: Description tags', tags);

      let composedBy = rowObject['track.metadata.artistName'];

      if (!composedBy) {
        const composersLength = Object.entries(rowObject).filter(
          ([k, _]) => k.startsWith('composers') && k.includes('firstName')
        ).length;

        const composersNames: string[] = [];
        for (let i = 0; i < composersLength; i++) {
          const firstName = rowObject[`composers.${i}.firstName`];
          const middleName = rowObject[`composers.${i}.middleName`];
          const lastName = rowObject[`composers.${i}.lastName`];
          composersNames.push(
            [firstName, middleName, lastName].filter((v) => !!v).join(' ')
          );
        }
        composedBy = composersNames.join('∞ ');
      }
      // workaround with ∞ for this field so commas are not replaced by semicolons
      const description = [
        catalogue,
        tags.map(([_, v]) => v).join('∞ '),
        composedBy ? `Composed by ${composedBy}` : '',
      ]
        .filter((v) => !!v)
        .join('∞ ');

      return description;
    },
  },
  {
    name: 'TRACK: Number',
    key: 'track.metadata.trackNumber',
  },
  {
    name: 'TRACK: Is Main',
    calculate: function (rowObject: any) {
      if (!rowObject.track) {
        return 'Y';
      }
      return rowObject.track.metadata.mainTrackNumber ? 'N' : 'Y';
    },
  },
  {
    name: 'TRACK: Main Track Number',
    key: 'track.metadata.mainTrackNumber',
  },
  {
    name: 'TRACK: Version',
    key: 'track.metadata.versionName',
  },
  {
    name: 'TRACK: Duration',
    key: 'track.metadata.duration',
  },
  {
    name: 'TRACK: BPM',
    key: 'track.metadata.bpm',
  },
  {
    name: 'TRACK: Lyrics',
    key: 'track.metadata.lyrics',
  },
  {
    name: 'TRACK: Search Metadata',
    value: '', //??
  },
  {
    name: 'TRACK: Audio Filename',
    calculate: function (rowObject: any) {
      if (rowObject['file.0.contentType'].indexOf('audio/wav') > -1) {
        return rowObject['file.0.name'];
      } else if (rowObject['file.1.contentType'].indexOf('audio/wav') > -1) {
        return rowObject['file.1.name'];
      } else if (rowObject['file.2.contentType'].indexOf('audio/wav') > -1) {
        return rowObject['file.2.name'];
      }
      return 'NO AUDIO FILE FOUND';
    },
  },
  {
    name: 'CATEGORY: Category',
    key: 'track.metadata.catalogue',
  },
  {
    name: 'CATEGORY: Moods',
    calculate: getTagsForCSVExport('tags', 'MOOD'),
  },
  {
    name: 'CATEGORY: Styles',
    calculate: getTagsForCSVExport('tags', 'STYLE'),
  },
  {
    name: 'CATEGORY: Music For',
    calculate: getTagsForCSVExport('tags', 'MUSICFOR'),
  },
  {
    name: 'CATEGORY: Instruments',
    calculate: getTagsForCSVExport('tags', 'INSTRUMENT'),
  },
  {
    name: 'CATEGORY: Elements',
    calculate: getTagsForCSVExport('tags', 'ELEMENT'),
  },
  {
    name: 'CATEGORY: Keywords',
    calculate: getTagsForCSVExport('tags', 'KEYWORD'),
  },
  {
    name: 'CATEGORY: Keynote',
    calculate: function (rowObject: any) {
      return `${rowObject['track.metadata.key']};${rowObject['track.metadata.scaleType']}`;
    },
  },
  {
    name: 'WRITER:1: First Name',
    key: 'composers.0.firstName',
  },
  {
    name: 'WRITER:1: Middle Name',
    key: 'composers.0.middleName',
  },
  {
    name: 'WRITER:1: Last Name',
    key: 'composers.0.lastName',
  },
  {
    name: 'WRITER:1: Capacity',
    value: 'Composer/Author',
  },
  {
    name: 'WRITER:1: Society',
    key: 'composers.0.pro',
  },
  {
    name: 'WRITER:1: IPI',
    key: 'composers.0.ipi',
  },
  {
    name: 'WRITER:1: Territory',
    value: 'WORLD',
  },
  {
    name: 'WRITER:1: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '13';
      } else if (rowObject['composers.2.firstName']) {
        return '17';
      } else if (rowObject['composers.1.firstName']) {
        return '25';
      } else {
        return '50';
      }
    },
  },
  {
    name: 'WRITER:1: Owner Mechanical Share %',
    value: '0',
  },
  {
    name: 'WRITER:1: Original Publisher',
    key: 'library.publisherName',
  },
  {
    name: 'WRITER:2: First Name',
    key: 'composers.1.firstName',
  },
  {
    name: 'WRITER:2: Middle Name',
    key: 'composers.1.middleName',
  },
  {
    name: 'WRITER:2: Last Name',
    key: 'composers.1.lastName',
  },
  {
    name: 'WRITER:2: Capacity',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return 'Composer/Author';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Society',
    key: 'composers.1.pro',
  },
  {
    name: 'WRITER:2: IPI',
    key: 'composers.1.ipi',
  },
  {
    name: 'WRITER:2: Territory',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return 'WORLD';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '13'; // TODO: HARDCODED!
      } else if (rowObject['composers.2.firstName']) {
        return '17'; // TODO: HARDCODED!
      } else if (rowObject['composers.1.firstName']) {
        return '25'; // TODO: HARDCODED!
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Owner Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Original Publisher',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return rowObject['library.publisherName'];
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: First Name',
    key: 'composers.2.firstName',
  },
  {
    name: 'WRITER:3: Middle Name',
    key: 'composers.2.middleName',
  },
  {
    name: 'WRITER:3: Last Name',
    key: 'composers.2.lastName',
  },
  {
    name: 'WRITER:3: Capacity',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return 'Composer/Author';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Society',
    key: 'composers.2.pro',
  },
  {
    name: 'WRITER:3: IPI',
    key: 'composers.2.ipi',
  },
  {
    name: 'WRITER:3: Territory',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return 'WORLD';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '12'; // TODO: HARDCODED!
      } else if (rowObject['composers.2.firstName']) {
        return '16'; // TODO: HARDCODED!
      } else if (rowObject['composers.1.firstName']) {
        return ''; // TODO: HARDCODED!
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Owner Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Original Publisher',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return rowObject['library.publisherName'];
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: First Name',
    key: 'composers.3.firstName',
  },
  {
    name: 'WRITER:4: Middle Name',
    key: 'composers.3.middleName',
  },
  {
    name: 'WRITER:4: Last Name',
    key: 'composers.3.lastName',
  },
  {
    name: 'WRITER:4: Capacity',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return 'Composer/Author';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Society',
    key: 'composers.3.pro',
  },
  {
    name: 'WRITER:4: IPI',
    key: 'composers.3.ipi',
  },
  {
    name: 'WRITER:4: Territory',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return 'WORLD';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '12'; // TODO: HARDCODED!
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Owner Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Original Publisher',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return rowObject['library.publisherName'];
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:5: First Name',
    key: 'composers.4.firstName',
  },
  {
    name: 'WRITER:5: Middle Name',
    key: 'composers.4.middleName',
  },
  {
    name: 'WRITER:5: Last Name',
    key: 'composers.4.lastName',
  },
  {
    name: 'WRITER:5: Capacity',
    calculate: function (rowObject: any) {
      if (rowObject['composers.4.firstName']) {
        return 'Composer/Author';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:5: Society',
    key: 'composers.4.pro',
  },
  {
    name: 'WRITER:5: IPI',
    key: 'composers.4.ipi',
  },
  {
    name: 'WRITER:5: Territory',
    calculate: function (rowObject: any) {
      if (rowObject['composers.4.firstName']) {
        return 'WORLD';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:5: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.4.firstName']) {
        return '50'; // TODO: HARDCODED!
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:5: Owner Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.4.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:5: Original Publisher',
    calculate: function (rowObject: any) {
      if (rowObject['composers.4.firstName']) {
        return rowObject['library.publisherName'];
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:6: First Name',
    key: 'composers.5.firstName',
  },
  {
    name: 'WRITER:6: Middle Name',
    key: 'composers.5.middleName',
  },
  {
    name: 'WRITER:6: Last Name',
    key: 'composers.5.lastName',
  },
  {
    name: 'WRITER:6: Capacity',
    calculate: function (rowObject: any) {
      if (rowObject['composers.5.firstName']) {
        return 'Composer/Author';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:6: Society',
    key: 'composers.5.pro',
  },
  {
    name: 'WRITER:6: IPI',
    key: 'composers.5.ipi',
  },
  {
    name: 'WRITER:6: Territory',
    calculate: function (rowObject: any) {
      if (rowObject['composers.5.firstName']) {
        return 'WORLD';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:6: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.5.firstName']) {
        return '50'; // TODO: HARDCODED!
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:6: Owner Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.5.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:6: Original Publisher',
    calculate: function (rowObject: any) {
      if (rowObject['composers.5.firstName']) {
        return rowObject['library.publisherName'];
      } else {
        return '';
      }
    },
  },
  // TODO: fix multi publishers
  {
    name: 'PUBLISHER:1: Name',
    key: 'library.publisherName',
  },
  {
    name: 'PUBLISHER:1: Capacity',
    value: 'Original Publisher',
  },
  {
    name: 'PUBLISHER:1: Society',
    key: 'library.pro',
  },
  {
    name: 'PUBLISHER:1: IPI',
    key: 'library.ipi',
  },
  {
    name: 'PUBLISHER:1: Territory',
    value: 'WORLD',
  },
  {
    name: 'PUBLISHER:1: Owner Performance Share %',
    value: '50',
  },
  {
    name: 'PUBLISHER:1: Owner Mechanical Share %',
    value: '100',
  },
  {
    name: 'PUBLISHER:1: Collection Performance Share %',
    value: '50',
  },
  {
    name: 'PUBLISHER:1: Collection Mechanical Share %',
    value: '100',
  },
  {
    name: 'CODE: ISWC',
    value: '',
  },
  {
    name: 'CODE: ISRC',
    key: 'track.isrc',
  },
  {
    name: 'CODE: PRS Tunecode',
    value: '',
  },
];
