import axios from 'axios';
import { toast } from 'react-toastify';
import { IFile } from '../api/services/file.service';

import { saveAs } from 'file-saver';
import api from '../api';
import { addTagsToWav, blobToUint8Array } from '../api/encoding/waveTagEncoder';
import { IProject } from '../api/services/project.service';
import { ITeam } from '../api/services/team.service';
import { ITrack } from '../api/services/track.service';

function getTrackStatus(
  track: ITrack,
  file: IFile | null = null,
  isAlbum = false
) {
  if (file && file.role === 'MAIN') {
    return `_unmastered`;
  }
  if (isAlbum) {
    return '';
  }
  if (file && file.role === 'MASTER') {
    return `_mastered`;
  }
  return '';
}

export function getWavFilenameSubmission(
  track: ITrack,
  composers: string[],
  activeTeam: ITeam,
  file: IFile
) {
  const artistNames = track.metadata.artistName || composers.join(' ');
  const name = `${activeTeam.libraryCode}_${artistNames}_${
    track.name
  }${getTrackStatus(track, file)}.wav`;
  return name;
}

export function getStemFilenameSubmission(
  track: ITrack,
  composers: string[],
  activeTeam: ITeam
) {
  const artistNames = track.metadata.artistName || composers.join(' ');
  return `${activeTeam.libraryCode}_${artistNames}_${track.name}_STEM_Grouped_Stems.zip`; // TODO: Julian wollte hier Drums???? ah an anderer Stelle? Bei zip download direkt?
}

export function getWavFilenameAlbum(
  track: ITrack,
  album: IProject | null,
  file: IFile | null,
  activeTeam: ITeam | null
) {
  return `${album?.metadata.albumCode}-${track.metadata.trackNumber.toString().padStart(2, '0')}_${track.name}_${activeTeam?.name}${getTrackStatus(track, file, true)}.wav`;
}

export function getStemFilenameAlbum(
  track: ITrack,
  album: IProject | null,
  activeTeam: ITeam | null
) {
  return `${album?.metadata.albumCode}-${track.metadata.trackNumber.toString().padStart(2, '0')}_${track.name}_${activeTeam?.name}_STEM_Grouped_Stems.zip`;
}

/**
 * Adds tags to a WAV file and returns a new Blob object.
 * @param blob - The original WAV file as a Blob object.
 * @param file - The file object associated with the WAV file.
 * @param res - The response object associated with the WAV file.
 * @param track - The track object associated with the WAV file.
 * @param project - The project object associated with the WAV file.
 * @param team - The team object associated with the WAV file.
 * @returns A new Blob object with the added tags.
 */
async function addTagsToWavFile(
  blob: Blob,
  file: IFile,
  res: Response,
  track: ITrack,
  project: IProject,
  team: ITeam
) {
  const [buffer, trackComposerResponse, trackTagsRespose] = await Promise.all([
    blobToUint8Array(blob),
    api.composer.getComposersByTrack(track.id),
    api.tag.getTagsByObject(track.id),
  ]);

  try {
    const wavBuffer = addTagsToWav(
      buffer,
      track,
      project,
      trackComposerResponse.data.result,
      team,
      trackTagsRespose.data.result
    );
    return new Blob([wavBuffer], { type: res.type });
  } catch (e) {
    toast.error(`Something went wrong in encoding: ${(e as any).message}`);
    return blob;
  }
}

/**
 * Downloads a file from the server and saves it to the user's device.
 * @param file - The file to download.
 * @param track - The track associated with the file, if any.
 * @param project - The project associated with the file, if any.
 * @param team - The team associated with the file, if any.
 */
export async function downloadFile(
  file: IFile,
  track: ITrack | null,
  project: IProject | null,
  team: ITeam | null,
  filename?: string
) {
  const name = filename || file.name;

  try {
    console.log('Getting Presigned URL');
    const { data } = await api.file.getFilePresignedDownloadUrl(file.id);
    if (data.result) {
      console.log('Fetching Data');
      const res = await fetch(data.result.presignedUrl);
      let blob = await res.blob();
      // If wav add encoded tags
      if (file.contentType === 'audio/wav') {
        console.log('Wave file detected, adding Tags');
        if (track === null || project === null || team === null) {
          saveAs(blob, name);
          return;
        }
        blob = await addTagsToWavFile(blob, file, res, track, project, team);
      }
      saveAs(blob, name);
    } else {
      toast.error('Could not get download link for file');
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data) {
        toast.error(e.response?.data.errorMessage);
      }
    } else {
      console.log(e);
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }
}
