import { IResponse } from '..';
import client from '../client';
import { IUserShort } from './project.service';

interface INewTeam {
  name: string;
}

interface INewTeamInvite {
  targetUserEmail: string;
  targetRole: string;
}

export interface ITeamInvite {
  id: string;

  teamId: string;
  teamRole: string;

  targetUserEmail: string;
  targetUserId: string;

  status: string;
  inviteOriginUserId: string;

  createdAt: string;
  updatedAt: string;
}

export interface ITeamMembership {
  inviteToken?: string;

  teamId?: string;
  userId: string;
  role: string;
  user: IUserShort;
  createdAt?: string;
}

export interface ITeam {
  id: string;
  name: string;
  publisherName: string;
  website: string;
  email: string;
  ipi: string;
  pro: string;

  countryCode: string;
  labelCode: string;
  libraryCode?: string;
  landingImageFileId: string | null;

  isrc_min: number;
  isrc_max: number;

  teamMembershipDTO?: ITeamMembership;

  createdAt?: Date;
  updatedAt?: Date;
}

async function getTeams() {
  return client.get<IResponse<ITeam>>('/team');
}

async function getTeam(id: string) {
  return client.get<IResponse<ITeam>>(`/team/${id}`);
}

async function updateTeam(id: string, team: Partial<ITeam>) {
  return client.put<IResponse<ITeam>>(`/team/${id}`, team);
}

async function getTeamMembers(id: string) {
  return client.get<IResponse<ITeamMembership[]>>(`/team/${id}/member`);
}

async function getTeamInvites(id: string) {
  return client.get<IResponse<ITeamInvite[]>>(`/team/${id}/invite`);
}

async function getMyTeams() {
  return client.get<IResponse<ITeam[]>>(`/team/my`);
}

async function addTeam(team: INewTeam) {
  return client.post<IResponse<ITeam>>(`/team`, team);
}

async function addMember(id: string, invite: Partial<ITeamMembership>) {
  return client.post<IResponse<ITeamMembership>>(`/team/${id}/member`, invite);
}

async function inviteMember(id: string, invite: INewTeamInvite) {
  return client.post<IResponse<ITeamInvite>>(
    `/team/${id}/member/invite`,
    invite
  );
}

// async function verifyInvite(token: string) {
//   return client.get<IResponse<ITeamInvite>>(`/invite/${token}`);
// }

const api = {
  getTeams,
  getTeam,
  addTeam,
  getMyTeams,
  getTeamMembers,
  getTeamInvites,
  inviteMember,
  // verifyInvite,
  addMember,
  updateTeam,
};

export default api;
