import saveAs from 'file-saver';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import {
  FaArrowLeft,
  FaCheck,
  FaFileArchive,
  FaFileDownload,
  FaSpinner,
  FaTrash,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api';
import { exportMapping_Cadenzabox } from '../../../api/csv/columnDefinitionsCadenzabox';
import { exportMapping_HarvestMedia } from '../../../api/csv/columnDefinitionsHarverstMedia';
import { exportAsCSV } from '../../../api/csv/csvExporter';
import { IFile } from '../../../api/services/file.service';
import { ITeam } from '../../../api/services/team.service';
import { ITrack } from '../../../api/services/track.service';
import { useAuth } from '../../../hooks/useAuth';
import useDialog from '../../../hooks/useDialog';
import { classNames, flattenObject } from '../../../utils';
import DeleteConfirmationDialog from '../../shared/dialog/DeleteConfirmationDialog';
import { useAlbumStudioState } from '../albumState';
import ExportOptionMenu from './actionBar/ExportOptionMenu';
import JSZip from 'jszip';
import { Mapping } from '../../../config/types';
import {
  getStemFilenameAlbum,
  getWavFilenameAlbum,
} from '../../../utils/fileHelper';
interface IFileMap {
  [key: string]: IFile[];
}

function buildFileMap(files: IFile[]) {
  const map: IFileMap = {};
  files.forEach((file) => {
    if (!map[file.parentId]) {
      map[file.parentId] = [file];
    } else {
      map[file.parentId] = [...map[file.parentId], file];
    }
  });
  return map;
}

const supportedExportFormats = {
  harvestMedia: exportMapping_HarvestMedia,
  cadenzabox: exportMapping_Cadenzabox,
};

export function AlbumActionBar() {
  const navigate = useNavigate();

  const {
    refreshData,
    activeAlbumTracks,
    activeFiles,
    isUpdating,
    activeAlbum,
    activeRole,
  } = useAlbumStudioState();

  const { isDialogOpened, openDialog, closeDialog } = useDialog();
  const [isDeletingAlbum, setIsDeletingAlbum] = useState(false);
  const { activeTeam } = useAuth();
  const [isExportRunning, setIsExportRunning] = useState(false);

  if (!activeAlbum) return null;

  async function exportSubmission(
    exportFormat: keyof typeof supportedExportFormats
  ) {
    if (!activeAlbum) return;

    const fileMap = buildFileMap(activeFiles);

    if (activeTeam) {
      const rowObjects = await Promise.all(
        activeAlbumTracks.map((track) =>
          getTrackInfo(track, fileMap[track.id], activeTeam)
        )
      );

      const csvSeparator = exportFormat === 'cadenzabox' ? ',' : ';';
      const multipleObjectDelimiter = exportFormat === 'cadenzabox' ? ';' : ',';
      const csvString = exportAsCSV(
        rowObjects,
        supportedExportFormats[exportFormat],
        csvSeparator,
        multipleObjectDelimiter
      );
      const blob = new Blob([csvString], { type: 'text/csv' });
      saveAs(
        blob,
        `${activeAlbum.name}_${exportFormat}_${DateTime.now().toISODate()}.csv`
      );
    } else {
      toast.error('team not set');
    }
  }

  async function getTrackInfo(track: ITrack, files: IFile[], library: ITeam) {
    if (!activeAlbum) return null;

    const [trackComposerResponse, submissionRes, trackTagsRes, albumTagsRes] =
      await Promise.all([
        api.composer.getComposersByTrack(track.id),
        api.project.getProject(track.projectId),
        api.tag.getTagsByObject(track.id),
        api.tag.getTagsByObject(activeAlbum.id),
      ]);

    return flattenObject({
      library,
      track,
      submission: submissionRes.data.result,
      file: files,
      album: activeAlbum,
      composers: trackComposerResponse.data.result,
      tags: _.groupBy(trackTagsRes.data.result, 'type'),
      albumTags: _.groupBy(albumTagsRes.data.result, 'type'),
    });
  }

  const confirmDeleteAlbum = () => {
    setIsDeletingAlbum(true);
    api.album
      .deleteAlbum(activeAlbum.id)
      .then(() => {
        setIsDeletingAlbum(false);
        toast.success(
          <>
            Album <span className='font-semibold'>{activeAlbum.name}</span>{' '}
            deleted successfully
          </>
        );
        navigate('/albums');
      })
      .catch(() => {
        setIsDeletingAlbum(false);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      });
  };

  function isRoleLoaded(role: string | null) {
    return role !== null && role !== '' && role !== undefined;
  }

  function isPublisher(role: string | null) {
    return role === 'OWNER' || 'MEMBER';
  }

  async function exportFilesAsZip(fileRole: 'MAIN' | 'MASTER' | 'STEMS') {
    setIsExportRunning(true);
    if (!activeAlbum) return;
    const projectId = activeAlbum.id;

    const [tracksRes, filesRes] = await Promise.all([
      api.track.getTracksByProject(projectId),
      api.file.getFilesByProject(projectId),
    ]);

    const files = filesRes.data.result;
    const tracks = tracksRes.data.result;

    const tracksMap = tracks.reduce(
      (
        acc: {
          [key: string]: ITrack;
        },
        track
      ) => {
        acc[track.id] = track;
        return acc;
      },
      {}
    );

    var zip = new JSZip();

    await Promise.all(
      files
        .filter(
          (f) =>
            [fileRole === 'MASTER' ? 'ARTWORK' : null, fileRole].indexOf(
              f.role
            ) > -1
        )
        .map(async (file) => {
          const urlResponse = await api.file.getFilePresignedDownloadUrl(
            file.id
          );
          const response = await fetch(urlResponse.data.result.presignedUrl);
          const fileBlob = await response.blob();
          const track = tracksMap[file.parentId];

          let filename = '';
          if (file.role === 'MAIN' || file.role === 'MASTER') {
            filename = getWavFilenameAlbum(
              track,
              activeAlbum,
              file,
              activeTeam
            );
          } else if (file.role === 'STEMS') {
            filename = getStemFilenameAlbum(track, activeAlbum, activeTeam);
          } else {
            console.error('Unknown file role', file);
          }
          zip.file(filename, fileBlob);
        })
    );

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const suffix =
      fileRole === 'MAIN'
        ? 'raw'
        : fileRole === 'MASTER'
          ? 'mastered'
          : 'stems';
    const filename = `${activeAlbum.metadata.albumCode}_${activeAlbum.name}_${suffix}_${DateTime.now().toISODate()}.zip`;

    saveAs(zipBlob, filename);
    setIsExportRunning(false);
  }

  return (
    <div className='flex flex-row justify-between'>
      <button
        className='flex items-center space-x-2 rounded bg-indigo-600 px-4 font-semibold text-white hover:bg-indigo-800'
        onClick={() => navigate('/albums')}
      >
        <FaArrowLeft />
        <div>Back</div>
      </button>

      <div className='flex items-center space-x-4'>
        {isRoleLoaded(activeRole) &&
          isPublisher(activeRole) &&
          activeAlbum.status === 'DONE' && (
            <ExportOptionMenu
              btnLabel={
                <>
                  <div>Download</div>
                  {isExportRunning && <FaSpinner className='animate-spin' />}
                  {!isExportRunning && <FaFileDownload />}
                </>
              }
              items={[
                {
                  label: 'Download raw files',
                  icon: (
                    <FaFileArchive
                      className='mr-2 h-5 w-5'
                      aria-hidden='true'
                    />
                  ),
                  onClick: () => activeTeam && exportFilesAsZip('MAIN'),
                },
                {
                  label: 'Download stem files',
                  icon: (
                    <FaFileArchive
                      className='mr-2 h-5 w-5'
                      aria-hidden='true'
                    />
                  ),
                  onClick: () => activeTeam && exportFilesAsZip('STEMS'),
                },
                {
                  label: 'Download mastered files',
                  icon: (
                    <FaFileArchive
                      className='mr-2 h-5 w-5'
                      aria-hidden='true'
                    />
                  ),
                  onClick: () => activeTeam && exportFilesAsZip('MASTER'),
                },
              ]}
            />
          )}
        {activeAlbum.status === 'DRAFT' && (
          <button
            className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
            onClick={() => {
              api.album
                .updateAlbumPartially(activeAlbum.id, {
                  status: 'DONE',
                })
                .then(() => {
                  refreshData('activeProject');
                })
                .catch((e) => {
                  const result = e.response.data.result || [];

                  if (
                    result.every(
                      (r: { fieldName?: string }) => r.fieldName === 'track'
                    )
                  ) {
                    toast.error(
                      <>
                        <div>
                          {result[0].message}
                          <div>Tracks:</div>
                          {result
                            .map((msj: { objectId?: string }) => msj.objectId)
                            .join(', ')}
                        </div>
                      </>
                    );
                  } else {
                    toast.error(result[0].message || 'Something went wrong');
                  }
                });
            }}
          >
            <div>Publish</div>
          </button>
        )}
        {/* {activeAlbum.status === 'DONE' && (
          <button className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'>
            <div>Publish changes</div>
          </button>
        )} */}
        <ExportOptionMenu
          items={[
            {
              label: 'Export Cadenzabox',
              onClick: () => activeTeam && exportSubmission('cadenzabox'),
            },
            {
              label: 'Export Harvest Media',
              onClick: () => activeTeam && exportSubmission('harvestMedia'),
            },
          ]}
        />
        <button
          className='group flex w-full items-center rounded-md text-sm'
          onClick={openDialog}
        >
          <FaTrash
            className='mr-2 h-5 w-5 hover:scale-110'
            aria-hidden='true'
          />
        </button>
        <div className='flex justify-end space-x-4'>
          <button
            disabled={!isUpdating}
            className={classNames(
              'flex  items-center space-x-2 rounded bg-emerald-600 px-4 py-2 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700',
              isUpdating ? 'bg-sky-600' : ''
            )}
          >
            {isUpdating ? <FaSpinner className='animate-spin' /> : <FaCheck />}
          </button>
        </div>
      </div>
      <DeleteConfirmationDialog
        isLoading={isDeletingAlbum}
        isOpen={isDialogOpened}
        title={`Delete album `}
        message={
          <>
            Do you really want to delete{' '}
            <span className='font-semibold'>"{activeAlbum.name}"</span> ?
          </>
        }
        close={closeDialog}
        onSubmit={confirmDeleteAlbum}
      />
    </div>
  );
}
